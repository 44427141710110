.container {
    height: 30rem;
    width: 100%;
    padding: 18px;
    border-radius: 10px;
    background-color: #F6F6F6;
}
.image {
    padding-top: 0.5rem;
}

.flexParent{
    display: flex;
    align-items: center;
    justify-content: center;
}