.assumptionsContainer{
    background-color: #F6F6F6;
    padding: 1rem;
}
.label,
.input{
    display: inline-block;
    width: 100%
}
.inputRow{
    padding: 0.5rem 0;
}

.input{
    padding: 0.3rem;
}
.inputCurrency {
    position: relative;
    display: inline-block;
    width: 100%;
}
.inputCurrency input {
    padding-left: 1rem;
    width: 100%;
}
  
.inputCurrency:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    content: "£";
    left: 0.5rem;
}