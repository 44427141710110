.accordionSection {
    display: flex;
    flex-direction: column;
  }
  
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    border-radius: 10px;
}

.accordion.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
  
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
    background-color: #ccc;
}

/* Style the accordion content title */
.accordionTitle {
    font-weight: 600;
    font-size: 14px;
}
  
/* Style the accordion chevron icon */
.accordionIcon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordionContent {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

/* Footer */
.footer {
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    padding: 2rem 5rem;
}
.borderLeft {
    border-left: 1px solid #d3d3d3!important;
}
.footerTitle{
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 15px;
    font-family: 'Sofia Pro';
}
.footerLink {
    color: white;
}
.footerLink:hover {
    cursor: pointer;
}

/* Icon */
.iconContainer {
    border-radius: 50%;
    background-color: #033238;
    position: relative;
}

/* Navbar */
.kpis {
    display: flex;
}
@media (max-width: 700px) {
    .kpis {display: none}
}

.navIcon {
    margin-right: 10px;
}

.homeBackground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}