.containerCentral {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 2rem;
    z-index: 1;
    width: 100%;
}
.homeContent {
    border-radius: 20px;
    box-shadow: 0 0 40px 4px #111118;
    padding: 80px 70px;
    margin-top: 180px !important;
    background-color: white;
}
.homeContentLeft {
    border-right: 1px solid var(--primary-color);
}
.homeBanner {
    height: 6rem;
    margin-bottom: 10px
}
.homeBackground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 700px) {
    .homeContent {
        margin-top: 0px;
    }
}

.homeRow {
    margin: 10px 0;
}
.navList {
    display: flex;
}
.planList {
    width: 8rem;
    background-color: var(--action-color);
    border-radius: 10px;
    display: flex;
    margin: 0 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.navList a,
.navList div {
    text-align: center;
    width: 100%;
    color: white;
    padding: 0.45rem;
}
.navList a:hover,
.navList div:hover {
    cursor: pointer;
}
.flexParent{
    display: flex;
    align-items: center;
    justify-content: center;
}