.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.containerCentral {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 2rem;
    z-index: 1;
    width: 100%;
}
.navList {
    display: flex;
}
.navList li {
    width: 5rem;
    border: #033238 2px solid;
    border-radius: 10px;
    display: flex;
    margin: 0 0.5rem;
}
.navList a {
    text-align: center;
    width: 100%;
    color: #033238;
    padding: 0.45rem;
}
.logo {
    height: 10rem;
    width: 10rem;
    display: block;
}

.homeContent {
    border-radius: 20px;
    box-shadow: 0 0 40px 4px #111118;
    padding: 80px 70px;
    margin-top: 180px !important;
    background-color: white;
}
.homeContentLeft {
    border-right: 1px solid var(--primary-color);
}
.homeBanner {
    height: 8rem;
    margin-bottom: 10px
}
.homeBackground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 700px) {
    .homeContent {
        margin-top: 0px;
    }
}