.container {
    height: 30rem;
    width: 100%;
    padding: 18px;
    border-radius: 10px;
    background-color: #F6F6F6;
}
.assumptionsContainer{
    background-color: #F6F6F6;
    padding: 1rem;
}
.label,
.input{
    display: inline-block;
    width: 100%
}
.inputRow{
    padding: 0.5rem 0;
}
.centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}