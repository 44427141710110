.assumptionsContainer{
    background-color: #F6F6F6;
    padding: 1rem;
}
.label,
.input{
    display: inline-block;
    width: 100%
}
.inputRow,
.inputRowSubmit{
    padding: 0.5rem 0;
}
.inputRowSubmit{
    border: 2px solid var(--dark-color);
}

.radioInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  
    width: 16px;
    height: 16px;
  
    border: 2px solid #999;
    transition: 0.2s all linear;
    margin-right: 5px;
  
    position: relative;
    top: 4px;
}
  
.radioInput:checked {
    border: 6px solid var(--secondary-color);
    opacity: 0.8;
}

.radioInput:disabled {
    border: 6px solid #999;
    opacity: 0.6;
    cursor: not-allowed;
}

.assumptionsContainer{
    background-color: #F6F6F6;
    padding: 1rem;
}

.border {
    border: 1px solid var(--dark-color);
    margin-bottom: 0.5rem;
}
.borderRight {
    border-right: 1px solid var(--dark-color);
}