.container {
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
    background-color: #F6F6F6;
    overflow: auto;
}



.navList {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.navList li {
    padding: 0.45rem;
    margin: 0 0.25rem;
    display: inline-block;
}
.selected {
    font-weight: bold;
    text-decoration: underline;
    color: #033238;
}
.item {
    border: none;
    margin: 0 0.3rem;
    padding: 0.45rem;
    background-color: #F6F6F6
}
.navList .item:hover {
    cursor: pointer;
}

.summaryContainer {
    overflow: auto;
}
.paddingBottom {
    padding-bottom: 1rem;
}
.marginBottom {
    margin-bottom: 1rem;
}
.underscore {
    border-bottom: black 2px solid;
}

.summaryRow {
    display: flex;
}
.leftColumn {
    font-weight: bold;
}
.rightColumn {
    margin-left: auto;
}