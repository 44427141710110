@font-face {
  font-family: "Sofia Pro";
  src: local("Sofia Pro"), url("fonts/sofiapro-regular-webfont.woff2") format("woff2");
}

/* Global Styles */
:root {
  --primary-color: #145A6C;
  --primary-color-light: #C0E1EB;
  --secondary-color: #05665F;
  --dark-color: #0E3545;
  --action-color: #F26448;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

.primary-color {color: #145A6C;}
.secondary-color {color: #05665F;}
.action-color {color: #F26448;}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Sofia Pro';
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

.font-primary {font-family: 'Sofia Pro';}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

h1 {
  font-family: 'Sofia Pro';
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Forms */
input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
}

input[type='submit'],
button {
  font: inherit;
  padding: 0 0.5rem;
}

fieldset {
  border: none;
  padding: 0.4rem;
}

label {
  font-size: 0.8rem;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  margin-bottom: 80rem;
  background: var(--primary-color);
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 10000;
  height: 70px;
}

.navbarLogoContainer {
  display: flex;
  align-content: center;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  gap: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar li {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a {
  color: #fff;
}

.container {
  margin-top: 70px;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
    height: 140px;
    position: relative;
    margin-bottom: 0;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

/* Date picker */
.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.loadingBackground {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.loadingContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Material UI style overrides */
.css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {color: var(--primary-color) !important;}
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {background-color: rgba(33, 124, 163, 0.08) !important;}
.css-187mznn-MuiSlider-root {color: var(--primary-color) !important;}
.css-eg0mwd-MuiSlider-thumb {border: 2px solid var(--primary-color) !important; color: #fff !important;}
