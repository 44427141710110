.modal {
    width: 75vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #F6F6F6;;
    transition: 0.25s ease-out;
    filter: blur(0);
    opacity: 1;
    visibility: visible;
    z-index: 1002;
    border-radius: 10px;
    border: 1px rgba(255, 255, 255, 0.75);
    padding: 1rem;
}
  
.modalBlock {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(68,68,68,0.5);
    z-index: 1001;
}

.modalClose {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modalClose:hover {
  color: black;
  cursor: pointer;
}

.selectionRow {
  margin-bottom: 1rem;
}
.centre {
  display: flex;
  align-items: center;
}