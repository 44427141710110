.row {
    margin-bottom: 1rem;
}
.button {
    float: right;
    margin: 0 0 1rem 0.5rem;
}
.header {
    padding: 0.5rem 0;
}
.rightAlign {
    position: absolute;
    right: 0;
    top: 13.5px;
}
.hidden {
    display: none
}
.summaryPane {
    background-color: #fff;
    margin: 0;
    height: 40rem;
    width: 100%;
    border-radius: 10px;
}
.mapPane {
    height: 40rem;
    width: 100%;
    position: relative;
}
.title {
    position: relative;
}