.kpiContainer {
    height: 10rem;
    width: 100%;
    position: relative;
    
}

.kpiFigure {
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    background-color: var(--secondary-color);
    border: 4px solid var(--primary-color);
    padding: 4px;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    display:table;
    vertical-align: middle;
}

.kpiFigure div {
    text-align:center;
    display:table-cell;
    vertical-align: middle;
    color: white;
}

.kpiValue {
    color: var(--primary-color);
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}

.kpiTitle {
    text-align: center;
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
}

.flexParent{
    display: flex;
    align-items: center;
    justify-content: center;
}